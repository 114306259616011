import { toast } from 'react-toastify';
import Loader from "react-loader-spinner";
import { Post, StartLiveStream } from '../../helpers/api'
import { useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { FormControl, FormGroup } from 'react-bootstrap';

function StartStream(props) {
    const [minutes, setMinutes] = useState(10)
    const [mobile, setMobile] = useState('03')
    const [customername, setCustomerName] = useState('')
    const [numberplate, setNumberPlate] = useState('')
    const [loader, setLoader] = useState(false)
    const [confirmationScreen, setConfirmationScreen] = useState(false)
    const [pinNumber, setPinNumber] = useState('')
    const [sms, setSms] = useState('')
    const [minutesError, setMinutesError] = useState('')
    const [mobileError, setMobileError] = useState('')
    const closeModal = () => {
        props.onCloseModal()
        setMobileError('')
        setMobile('03')
        setCustomerName('')
        setMinutes(10)
        setNumberPlate('')
        setPinNumber('')
        setConfirmationScreen(false)
    }
    document.addEventListener('keyup', (e) => {
        if (e.key === 'Enter') {
            if (confirmationScreen) {
                ValidatePIN()
            } else {
                StartStreaming()
            }
        }
    })
    const ValidatePIN = async () => {
        setLoader(true)

        try {
            const res = await StartLiveStream(props.camera.id, minutes, pinNumber, [
                { Name: "NotifyMobileNo", attributeId: 3, Value: mobile },
                { Name: "LicensePlateNo", attributeId: 4, Value: numberplate },
                { Name: "CustomerName", attributeId: 5, Value: customername }
            ])
            setMobile('03')
            setMinutes(10)
            setNumberPlate('')
            setCustomerName('')
            setConfirmationScreen(false)
            setPinNumber('')
            setLoader(false)
            props.onResponse(res)
        } catch (error) {
            toast.error(error.toString(), {
                position: "top-center",
                autoClose: false,
                closeOnClick: true,
                onClose: () => setLoader(false)
            });
        }
    }
    const getSMSDetails = () => {
        const data = {
            sourceId: props.camera.id,
            durationMinutes: minutes,
            attributes: [
                { Name: "NotifyMobileNo", attributeId: 3, Value: mobile },
                { Name: "LicensePlateNo", attributeId: 4, Value: numberplate },
                { Name: "CustomerName", attributeId: 5, Value: customername }
            ]
        }
        Post('/LiveStream/getsmsmessage', data).then(res => {
            if (res) {
                setSms(res.message)
                setConfirmationScreen(true)
                setLoader(false)
            } else {
                setLoader(false)
                setMobileError('Customer Phone is required')
                toast('Unable to Start Stream. Try Again!', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    type: "error"
                });
            }
        })
    }
    const checkAndSetMinutes = e => {
        setMinutes(e.target.value)
        if (parseInt(e.target.value, 10) > 180) {
            setMinutesError('Max Stream time is 180 minutes.')
        } else if (parseInt(e.target.value, 10) < 5) {
            setMinutesError('Min Stream time is 5 minutes.')
        } else {
            setMinutesError('')
            setMinutes(e.target.value)
        }
    }
    const checkAndSetMobile = e => {
        if (!e.target.value) {
            setMobileError('Customer Mobile is required')
        }
        if (/^[0-9][0-9 -]*$/.test(e.target.value)) {
            setMobile(e.target.value)
            setMobileError('')
        }
    }
    const StartStreaming = () => {
        if (mobile.length < 11) {
            setMobileError('Invalid Customer Mobile')
        } else if (minutes && mobile) {
            setLoader(true)
            getSMSDetails()
        } else {
            toast('Duration and Customer Phone is Required!', {
                position: "top-center",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                type: "error"
            });
        }
    }
    return (
        <Modal className="startStreamModal" show={props.isOpen} onHide={closeModal} backdrop="static" size="lg" centered>
            <div className="overlay-loader" style={{ display: loader ? 'block' : 'none' }}>
                <Loader
                    className="loader-item"
                    type="ThreeDots"
                    color="#E52D27"
                    height={100}
                    width={100}
                />
            </div>
            <Modal.Header closeButton>
                <Modal.Title>Start Camera {props.camera.name}</Modal.Title>
            </Modal.Header>
            {confirmationScreen ? <Modal.Body>
                <div className='row'>
                    <div className="col">
                        <h3>Confirmation</h3>
                    </div>
                </div>
                <div className='row'>
                    <div className="col">
                        <p>{sms}</p>
                    </div>
                </div>
                <Row className='justify-content-center'>
                    <Col sm='8' md='6' lg='4'>
                        <Form autoComplete="off">
                            <FormGroup>
                                <Form.Label>Please Enter Your PIN</Form.Label>
                                <FormControl
                                    name="pin"
                                    className='user-pin'
                                    type="password"
                                    maxLength={4}
                                    value={pinNumber}
                                    onChange={e => setPinNumber(e.target.value)}
                                    placeholder="xxxx"
                                    autoComplete="off" />
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
            </Modal.Body> : <Modal.Body>
                <div className='row'>
                    <div className="col-sm-12 col-md-6">
                        <div className='form-group'>
                            <label>Stream Duration (In Minutes)</label>
                            <input type="number" className="form-control" min={5} max={180} value={minutes} onChange={checkAndSetMinutes} placeholder="Time in minutes"></input>
                            {minutesError ? <span style={{ color: 'red', fontSize: '12px' }}>{minutesError}</span> : null}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className='form-group'>
                            <label>Customer Mobile *</label>
                            <input className="form-control" type="text" maxLength={11} minLength={2} value={mobile} onChange={checkAndSetMobile} placeholder="03xxxxxxxxx"></input>
                            {mobileError ? <span style={{ color: 'red', fontSize: '12px' }}>{mobileError}</span> : null}
                        </div>
                    </div>
                </div>
                <br />
                <div className='row'>
                    <div className="col-sm-12 col-md-6">
                        <div className='form-group'>
                            <label>Customer Name</label>
                            <input type="text" className="form-control" maxLength={25} value={customername} onChange={e => setCustomerName(e.target.value)} placeholder="Name"></input>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className='form-group'>
                            <label>Vehicle Registration Number</label>
                            <input className="form-control" type="text" value={numberplate} maxLength={11} onChange={e => setNumberPlate(e.target.value)} placeholder=""></input>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            }
            <Modal.Footer>
                <Button variant="link" onClick={closeModal}>Cancel</Button>
                {confirmationScreen
                    ? <Button onClick={ValidatePIN}>Send Message and Start Camera</Button>
                    : <Button disabled={mobile === '03' || minutesError} onClick={StartStreaming}>Next</Button>}
            </Modal.Footer>
        </Modal>
    );
}

export default StartStream;