import Footer from '../components/footer';

function LandingPage() {
  return (

    <div className="container">
      <div className="row">
        <div className="col">
          <div className="card mt-3">
            <div className="card-header">
              Honda Atlas Cars (Pakistan) Ltd
            </div>
            <div className="row g-0">
              <div className="col-md-4 text-center">
                <div className='p-4'>
                  <img src="/Honda-logo.png" className="img-fluid rounded-start" alt="Honda Logo" />
                </div>
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <h1 className="card-title">Honda LiveService</h1>
                  <p className="card-text">Now you can view live video of your vehicle repair at select Honda Pakistan Dealerhsips.</p>
                  <p className="card-text"><small className="text-muted">Contact your local dealerhsip for details</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default LandingPage;