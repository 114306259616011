import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './login/login'
import Main from './main'
import Stream from './components/stream'
import LandingPage from './landingPage/landingPage'
import PrivateRoute from './helpers/privateRoute'

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/admin/login" component={Login}></Route>
        <Route exact path="/" component={LandingPage}></Route>
        <PrivateRoute path="/admin" component={Main}></PrivateRoute>
        <Route exact path="/:id" component={Stream}></Route>
      </Switch>
    </Router>
  );
}

export default App;
