import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { isLoggedIn } from '../helpers/authentication';
import { AuthLogin } from '../helpers/api';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Image from 'react-bootstrap/Image';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../components/footer';

function Login() {

	const history = useHistory();
	if (isLoggedIn()) {
		history.push('/admin')
	}

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [validated, setValidated] = useState(false);

	const handleSubmit = async (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === true) {
			event.preventDefault();
			event.stopPropagation();
			setValidated(true);
			await Authenticate()
		}
		else {
			event.preventDefault();
			event.stopPropagation();
		}

	};

	const Authenticate = async () => {
		try {
			const data = await AuthLogin(username, password)
			localStorage.setItem('token', data.token)
			localStorage.setItem('type', data.roles[0])
			history.push('/admin')
		} catch (error) {
			toast.error(error.toString())
		}
	}
	return (
		<>
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				closeOnClick
				pauseOnHover />
			<Container className='my-5 pb-5'>
				<Row className='justify-content-center'>
					<Col sm='8' md='6' lg='4'>
						<div className='text-center mb-4'>
							<Image src="/Honda-logo.png" fluid />
						</div>
						<Card>
							<Card.Header>LiveService Admin Panel</Card.Header>
							<Card.Body>
								<Form noValidate validated={validated} onSubmit={handleSubmit}>
									<Form.Group className="mb-3">
										<Form.Label>Email address</Form.Label>
										<InputGroup>
											<InputGroup.Text><i className="fas fa-user"></i></InputGroup.Text>
											<Form.Control type="email" required value={username} onChange={e => setUsername(e.target.value)} placeholder="email" />
											<Form.Control.Feedback type="invalid">Please enter valid email.</Form.Control.Feedback>
										</InputGroup>
									</Form.Group>
									<Form.Group className="mb-3" controlId="formBasicPassword">
										<Form.Label>Password</Form.Label>
										<InputGroup>
											<InputGroup.Text><i className="fas fa-key"></i></InputGroup.Text>
											<Form.Control type="password" required value={password} onChange={e => setPassword(e.target.value)} placeholder="password" />
											<Form.Control.Feedback type="invalid">Password cannot be blank.</Form.Control.Feedback>
										</InputGroup>
									</Form.Group>
									<Form.Group>
										<Button variant='primary' type='submit' className='text-white'>Login</Button>
									</Form.Group>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
			<Footer />
		</>

	);
}

export default Login;
