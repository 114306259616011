import { useState, useEffect } from 'react'
import { GetStreamSources, GetLiveStreams, StopLiveStream } from '../helpers/api'
import StartStream from '../components/dialogs/startStream'
import ViewHistory from '../components/dialogs/streamHistory'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
function Admin() {
    const [cameraList, setList] = useState([])
    const [isStartModalOpen, setStreamModal] = useState(false)
    const [isHistoryModalOpen, setHistroyModal] = useState(false)
    const [cameraHistory, setCameraHistroy] = useState([])
    const [selectedCamera, setSelectedCamera] = useState({})
    const [requestSent, setRequestSent] = useState(false)
    const [loader, setLoader] = useState(false)
    useEffect(() => {
        if (!requestSent) {
            setLoader(true)
            getList()
        }
    }, [requestSent])
    const getList = async () => {
        try {
            const list = await GetStreamSources()
            setLoader(false)
            setRequestSent(true)
            setList(list)
        } catch (error) {
            setLoader(false)
            toast.error(error.toString(), {
                position: "top-center",
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }
    const refreshList = () => {
        setLoader(true)
        getList()
    }
    const startStreaming = camera => {
        setStreamModal(true)
        setSelectedCamera(camera)
    }
    const stopStreaming = async (camera) => {
        setLoader(true)
        try {
            await StopLiveStream(camera.liveStreamId)
            setRequestSent(true)
            const list = [...cameraList]
            list.forEach(el => {
                if (el.id === camera.id) {
                    camera.streaming = false
                }
            })
            setList(list)
            setLoader(false)
            toast.success('Camera Stopped', {
                position: "top-center",
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } catch (error) {
            setLoader(false)
            toast.error(error.toString(), {
                position: "top-center",
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }
    const getRecentHistory = async (camera) => {
        setLoader(true)
        // const dateTo = new Date()
        // const dateFrom = new Date()
        // dateFrom.setDate(dateTo.getDate() - 7);
        // await getHistory(camera, dateFrom, dateTo)
        await getHistory(camera)
    }

    const getHistory = async (camera = null, dateFrom = null, dateTo = null) => {
        setLoader(true)
        try {
            const data = await GetLiveStreams(camera?.id, true, dateFrom, dateTo)
            setCameraHistroy(data)
            if (camera) setSelectedCamera(camera)
            setHistroyModal(true)
            setLoader(false)
        } catch (error) {
            setLoader(false)
            toast.error(error.toString(), {
                position: "top-center",
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true
            });
        }
    }

    const copyLink = async (link) => {
        var base_url = window.location.origin
        await navigator.clipboard.writeText(`${base_url}${link}`);
        toast('Link Copied!', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            type: "success"
        });
    }
    const setResponse = (res) => {
        const list = [...cameraList]
        list.forEach(el => {
            if (el.id === selectedCamera.id) {
                el.streaming = true
                el.code = res.code
                el.liveStreamId = res.id
            }
        })
        setList(list)
        setStreamModal(false)
        toast('Stream Started!', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            type: "success"
        });
    }
    const closeModal = () => {
        setStreamModal(false)
        setHistroyModal(false)
    }
    return (
        <div className="container my-4 pb-5">
            <div className={'overlay-loader ' + (loader ? 'd-block' : 'd-none')}>
                <Loader
                    className="loader-item"
                    type="ThreeDots"
                    color="#E52D27"
                    height={100}
                    width={100}
                />
            </div>
            <ToastContainer />
            <StartStream isOpen={isStartModalOpen} camera={selectedCamera} onCloseModal={closeModal} onResponse={setResponse}></StartStream>
            <ViewHistory isOpen={isHistoryModalOpen} camera={selectedCamera} history={cameraHistory} onCloseModal={closeModal}></ViewHistory>
            <div className="row">
                <div className="col">
                    <h4>Cameras</h4>
                </div>
            </div>

            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col"><i className="fas fa-th-list"></i></th>
                        <th scope="col">Name</th>
                        <th>
                            <div className='float-end'>
                                <div className="d-inline-block m-1">
                                    <button className="btn btn-sm btn-primary" onClick={refreshList}><i className="fas fa-sync-alt"></i> Refresh List</button>
                                </div>
                                <div className="d-inline-block m-1">
                                    <button className="btn btn-sm btn-secondary" onClick={() => getHistory()}><i className="fas fa-list"></i> All History</button>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {cameraList.length > 0 ? cameraList.map((camera, i) => (
                        <tr key={i}>
                            <th scope="row"><span className='me-4'>{i + 1}</span>{camera.streaming ? <i className="fas fa-video text-success"></i> : <i className="fas fa-video-slash text-secondary"></i>}</th>
                            <td>{camera.name}</td>
                            <td>
                                <div className='float-end'>
                                    <div className='btn-group btn-group-sm'>
                                        {camera.streaming ? <a className="btn btn-outline-secondary" href={`/${camera.code}`} target="_blank" rel="noreferrer"><i className="fas fa-video"></i> View</a> : null}
                                        {camera.streaming ? <button className="btn btn-outline-secondary" href="#" onClick={() => copyLink(`/${camera.code}`)}><i className="fas fa-copy"></i> Copy Link</button> : null}
                                    </div>
                                    <div className="d-inline-block m-1">
                                        {camera.streaming
                                            ? <button className="btn btn-sm btn-danger" onClick={() => stopStreaming(camera)} ><i className="fas fa-stop-circle"></i> Stop</button>
                                            : <button className="btn btn-sm btn-success" onClick={() => startStreaming(camera)}><i className="fas fa-play-circle"></i> Start</button>}
                                    </div>
                                    <div className="d-inline-block m-1">
                                        <button className="btn btn-sm btn-secondary" onClick={() => getRecentHistory(camera)}><i className="fas fa-list"></i> Log History</button>
                                    </div>

                                </div>
                            </td>
                        </tr>
                    )) : ''}
                </tbody>
            </table>
        </div>
    );
}

export default Admin;