
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { Get } from '../helpers/api'
import ReactPlayer from 'react-player'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
function Stream() {
    const { id } = useParams()
    const [requestSent, setRequestSent] = useState(false)
    const [streamUri, setStreamUri] = useState('')
    const [attributes, setAttributes] = useState([])
    const [loader, setLoader] = useState(false)
    const [isStreamExipred, setSteamExpired] = useState(false)
    useEffect(() => {
        if (!requestSent) {
            GetStream(id)
        }
    })
    const GetStream = (sourceId) => {
        setLoader(true)
        Get(`/LiveStream/${sourceId}`).then(res => {
            if (res) {
                if (res.expired) {
                    setLoader(false)
                    setSteamExpired(true)
                    setRequestSent(true)
                } else {
                    setRequestSent(true)
                    setStreamUri(`${process.env.REACT_APP_PLAYER_BASE_URL}${res.fileUri}`)
                    setLoader(false)
                    setAttributes(res.attributes)
                }
            }
        })
    }
    return (
        <div className="container">
            <div className="overlay-loader" style={{ display: loader ? 'block' : 'none' }}>
                <Loader
                    className="loader-item"
                    type="ThreeDots"
                    color="#E52D27"
                    height={100}
                    width={100}
                />
            </div>
            <div className="row">
                <div className="col-12">
                    <h1 className='text-center'>Honda Atlas Cars (Pakistan) Ltd</h1>
                </div>
            </div>

            {isStreamExipred ?
                <div className='main'>
                    <h2 className='text-center' style={{ color: 'red', marginTop: '100px' }}>
                        Stream is Expired!
                    </h2>
                </div> : <div className='row'>
                    <div className='col-md-8'>{streamUri ? <ReactPlayer url={streamUri} muted playing playsinline width='100%' height='100%' /> : null}</div>
                    <div className='col-md-4'>
                        {attributes && attributes.length > 0 ? attributes.filter(x => x.name !== 'SmsMessage').map((el, i) => (
                            <div className='row' key={i}>
                                <div className='col-6'><span><b>{el.name.replace(/([a-z])([A-Z])/g, `$1 $2`)}:&nbsp;</b></span></div>
                                <div className='col-6'><span>{el.value}</span></div>
                            </div>
                        )) : ''}
                    </div>
                </div>}


        </div>
    );
}

export default Stream;