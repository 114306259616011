import Nav from 'react-bootstrap/Nav'

function Footer() {
  return (
    <Nav className="fixed-bottom navbar-light bg-light mt-5 pt-3 footer">
      <p className='text-center'>© {new Date().getFullYear()} Honda Atlas Cars (Pakistan) Ltd.</p>
    </Nav>
  );
}

export default Footer;