import { getToken } from './authentication';
const APIURL = process.env.REACT_APP_API_BASE_URL

function Post(url, body) {
    const opt = {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() },
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(body)
    }
    return fetch(APIURL + url, opt).then(r => {
        if (r.status === 401) {
            localStorage.clear()
            window.location.href = '/admin/login'
        } else {
            return r.json()
        }
    }).then((res) => {
        if (res) {
            return res;
        }
    }).catch(err => {
        if (err) {
            return err;
        }
    })
}

function Get(url) {
    const opt = {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() },
        method: 'GET',
        mode: 'cors',
    }
    return fetch(APIURL + url, opt).then(r => {
        if (r.status === 401) {
            localStorage.clear()
            window.location.href = '/admin/login'
        } else {
            return r.json()
        }
    }).then((res) => {
        if (res) {
            return res;
        }
    }).catch(err => {
        if (err) {
            return err;
        }
    })
}

const AuthLogin = async (username, password) => {
    const response = await fetch(`${APIURL}/auth/login`, {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({ username, password })
    })

    return getJson(response)
}

const GetLiveStreams = async (
    sourceId = null,
    includeExpired = null,
    dateFromObj = null,
    dateToObj = null) => {

    const dateFrom = dateFromObj?.toISOString()
    const dateTo = dateToObj?.toISOString()

    const url = new URL(`${APIURL}/livestream`)
    const params = new URLSearchParams()

    if (includeExpired !== undefined && includeExpired !== null)
        params.append('includeExpired', includeExpired)
    if (sourceId !== undefined && sourceId !== null)
        params.append('sourceId', sourceId)
    if (dateFrom !== undefined && dateFrom !== null)
        params.append('dateFrom', dateFrom)
    if (dateTo !== undefined && dateTo !== null)
        params.append('dateTo', dateTo)

    url.search = params.toString();

    const response = await fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        },
        method: 'GET',
        mode: 'cors'
    })

    return getJson(response)
}

const StopLiveStream = async (id) => {
    const response = await fetch(`${APIURL}/livestream/stop`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        },
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({ id })
    })
    return getJson(response)
}

const GetStreamSources = async () => {
    const url = new URL(`${APIURL}/StreamSource`)
    const response = await fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        },
        method: 'GET',
        mode: 'cors'
    })
    return getJson(response)
}

const StartLiveStream = async (sourceId, durationMinutes, pinCode, attributes) => {
    const url = new URL(`${APIURL}/LiveStream/Start`)
    const response = await fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        },
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({ sourceId, durationMinutes, pinCode, attributes })
    })
    return getJson(response)
}

const getJson = async (fetchResponse) => {

    const text = await fetchResponse.text()
    let data = null
    try {
        data = JSON.parse(text)
    } catch {

    }

    if (!fetchResponse.ok) {
        if (fetchResponse.status === 401) {
            localStorage.clear()
            window.location.href = '/admin/login'
            return
        }
        let message = 'Error'
        try {
            message = data?.title
            if (data.errors) {
                message += JSON.stringify(data.errors)
            }
        } catch {
            message = text
        }
        message += `(${fetchResponse.status} ${fetchResponse.statusText})`
        throw new Error(message);
    }

    return data
}


export {
    Post,
    Get,
    AuthLogin,
    GetLiveStreams,
    StopLiveStream,
    GetStreamSources, 
    StartLiveStream
}