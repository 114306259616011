import { Link, useHistory } from 'react-router-dom'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'

function Header() {
  const history = useHistory()
  const logout = () => {
    localStorage.clear()
    history.push('/admin/login')
  }
  return (
    <nav className="navbar navbar-expand-lg sticky-top navbar-light bg-light main-header">
      <div className="container-fluid">
        <div className='d-inline-block p-2'>
          <Link className="navbar-brand" to="/">
            <Image src='/Honda-logo.png' alt="logo" width={200} />
          </Link>
        </div>
        <div className='d-inline-block float-end'>
          <Button variant='link' size='sm' onClick={() => logout()} className="my-2"><i className="fas fa-sign-out-alt"></i> Logout</Button>
        </div>
      </div>
    </nav>
  );
}

export default Header;