import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import CSV from 'react-json-to-csv'

function StreamHistory(props) {
    const closeModal = () => {
        props.onCloseModal()
    }

    const getHistoryCsv = () => {
        return props.history.map((liveStream) => {
            const csvRow = {
                SourceName: liveStream.sourceName,
                StartTime: new Date(liveStream.startTime).toDateString() + '  ' + new Date(liveStream.startTime).toLocaleTimeString(),
                EndTime: new Date(liveStream.endTime).toDateString() + '  ' + new Date(liveStream.endTime).toLocaleTimeString(),
                StoppedAt: new Date(liveStream.stoppedAt).toDateString() + '  ' + new Date(liveStream.stoppedAt).toLocaleTimeString(),
                Expired: liveStream.expired,
                UrlCode: liveStream.code,
                CustomerName: liveStream.attributes.find(x => x.attributeId === 5) ? liveStream.attributes.find(x => x.attributeId === 5).value : '',
                MobileNumber: liveStream.attributes.find(x => x.attributeId === 3) ? liveStream.attributes.find(x => x.attributeId === 3).value : '',
                RegistrationNumber: liveStream.attributes.find(x => x.attributeId === 4) ? liveStream.attributes.find(x => x.attributeId === 4).value : ''
            }
            return csvRow
        })
    }

    return (
        <Modal show={props.isOpen} onHide={closeModal} backdrop="static" fullscreen centered>
            <Modal.Header closeButton>
                <Modal.Title>Stream History of {props.camera.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Source Name</th>
                            <th scope="col">Started On</th>
                            <th scope="col">Ended On</th>
                            <th scope="col">Status</th>
                            <th scope="col">Customer Name</th>
                            <th scope="col">Customer Phone</th>
                            <th scope="col">Registration Number</th>
                        </tr>
                    </thead>
                    <tbody style={{ maxHeight: '500px', overflow: 'auto' }}>

                        {props.history.length > 0 ? props.history.map((camera, i) => (
                            <tr key={i}>
                                <th style={{ fontSize: 12 }}>{i + 1}</th>
                                <td style={{ fontSize: 12 }}>{camera.sourceName}</td>
                                <td style={{ fontSize: 12 }}>{new Date(camera.startTime).toDateString() + '  ' + new Date(camera.startTime).toLocaleTimeString()}</td>
                                <td style={{ fontSize: 12 }}>{new Date(camera.endTime).toDateString() + '  ' + new Date(camera.endTime).toLocaleTimeString()}</td>
                                <td style={{ fontSize: 12 }}>{camera.expired ? 'Expired' : 'Active'}</td>
                                <td style={{ fontSize: 12 }}>{camera.attributes.find(x => x.attributeId === 5) ? camera.attributes.find(x => x.attributeId === 5).value : ''}</td>
                                <td style={{ fontSize: 12 }}>{camera.attributes.find(x => x.attributeId === 3) ? camera.attributes.find(x => x.attributeId === 3).value : ''}</td>
                                <td style={{ fontSize: 12 }}>{camera.attributes.find(x => x.attributeId === 4) ? camera.attributes.find(x => x.attributeId === 4).value : ''}</td>
                            </tr>
                        )) : ''}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <CSV className="btn btn-secondary" data={getHistoryCsv()} filename='history.csv'><i className="fas fa-file-download"></i> Download CSV</CSV>
                <Button variant='link' onClick={closeModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default StreamHistory;