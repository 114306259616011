function isLoggedIn() {
  const token = localStorage.getItem('token')
  if (token) {
    return true
  }
  return false
}

function getToken() {
  return localStorage.getItem('token')
}
function getType() {
  return localStorage.getItem('type')
}
export { isLoggedIn, getToken, getType }
