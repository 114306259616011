
import Header from './components/header'
import { getType } from './helpers/authentication'
import Admin from './admin/adminpanel'
import User from './user/userpanel'
import Footer from './components/footer';
function Main() {
    if (getType() === 'User') {
        return (
            <div className="main">
                <Header />
                <div className="container">
                    <User />
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <Header />
                <Admin />
                <Footer />
            </div>
        );
    }
}

export default Main;
